

































































import { Component, Vue } from 'vue-property-decorator';

import httpClient from '@/services/http-client';
import { toasts } from '@/utils/toasts';

@Component({
  name: 'DeveloperTools',
})
export default class extends Vue {
  get xhrRequestRunning() {
    return this.$store.state.xhrRequestRunning;
  }

  async onDeleteInventoryDocumentRecords() {
    try {
      this.$store.dispatch('mark_xhr_request_running');
      await httpClient.post('/api/internal-developer-tools/delete-inventory-document-records');
      toasts.success('Datele au fost șterse');
    } catch (err) {
      toasts.error(`Eroare! ${err}`);
    } finally {
      this.$store.dispatch('unmark_xhr_request_running');
    }
  }
}
